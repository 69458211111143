import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { HeaderText } from './header-text';
import { Panel1 } from './panel1';

import './styles/contacts.css';

import 'leaflet/dist/leaflet.css';
import { FormComponent } from './form';
import { useDispatch, useSelector } from 'react-redux';
import { contactResponseRecieved, contactSend, loginResponseRecieved } from '../../actions';
import { reset } from 'redux-form';
import { toast } from 'react-toastify';
import { getContactResponse } from '../../selectors';

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
  
L.Marker.prototype.options.icon = DefaultIcon;

const positionArray = [{
    position: ['22.527590','88.348540'],
    office: 'CORPORATE OFFICE'
}]

export const Contact = ({}) => {
    const dispatch = useDispatch();
    const response = useSelector(getContactResponse);
    const onSubmit = (payload) => {
        dispatch(contactSend(payload));
    }
    React.useEffect(()=>{
        if(response) {
            toast(response.type==='SUCCESS' ? 'Saved':'Error Occured');
            if(response.type==='SUCCESS') {
                dispatch(reset('contact'));
            }
            dispatch(contactResponseRecieved(undefined));
        }
    },[response])
    React.useEffect(()=>{
        return (()=>{
            dispatch(loginResponseRecieved(undefined));
        })
    },[])
    return (
        <>
        <Panel1 
            addBgImage
            left={
            <div className='contact-form-left'>
                <HeaderText color={'#fff'} text={'Our Contacts'} />
                <div className='inner'>
                    <div className='item'>
                        <div className='icon'>
                            <Icon name='location arrow' size='huge'></Icon>
                        </div>
                        <h4 className='title'>
                            Our Office
                        </h4>
                        <div className='description'>
                            123, NewLenox,Chicago, IL 60606
                        </div>
                    </div>
                    <div className='item mt-5'>
                        <div className='icon'>
                            <Icon name='mobile alternate' size='huge'></Icon>
                        </div>
                        <h4 className='title'>
                            Phone
                        </h4>
                        <a className='description'>
                            9092032302
                        </a>
                    </div>
                    <div className='item mt-5'>
                        <div className='icon'>
                            <Icon name='mail' size='huge'></Icon>
                        </div>
                        <h4 className='title'>
                            Email Address
                        </h4>
                        <a className='description'>
                            test@test.com
                        </a>
                    </div>
                </div>
            </div>}
            right={
                <div className='p-5'>
                    <div className='p-5'>
                        <HeaderText color={'#000'} text={'Request Quote'} />
                        <br />
                        <FormComponent
                            onSubmit={onSubmit}
                            saveText={'Send message'}
                            form='contact'
                            initialValues={{}}
                            fieldList={[
                                {
                                    type: 'text',
                                    key: 'name',
                                    name: 'Your name',
                                    required: true,
                                    size: '6'
                                },
                                {
                                    type: 'text',
                                    key: 'email',
                                    name: 'Your email',
                                    required: true,
                                    size: '6'
                                },
                                {
                                    type: 'textarea',
                                    key: 'message',
                                    name: 'Your message',
                                    required: true,
                                    size: '12'
                                }
                            ]}
                        />
                    </div>
                </div>
            }
        />
        <MapContainer style={{height: '400px'}} center={[22.572645, 88.363892]} zoom={10} scrollWheelZoom={false}>
                            <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {
                                positionArray.map(pos=>(
                                    <Marker position={pos.position} key={pos.office}>
                                        <Popup keepInView>
                                            {pos.office}
                                        </Popup>
                                    </Marker>
                                ))
                            }
                            
                        </MapContainer>
        </>
    )
}