import * as React from 'react';

import { Slider } from '../../utils/slider';
import { Section1 } from '../../utils/section1';
import { Col, Image, Row } from 'react-bootstrap';
import { HeaderTopText } from '../../utils/header-top-text';
import { HeaderText } from '../../utils/header-text';
import { Section2 } from '../../utils/section2';
import { Panel1 } from '../../utils/panel1';
import { Icon } from 'semantic-ui-react';
import { Section3 } from '../../utils/section3';
import { ProductFilter } from '../../utils/product-filter';
import { Blogs } from '../../utils/blogs';
import { Contact } from '../../utils/contact';
import { Body } from '../../utils/body';
import { useDispatch, useSelector } from 'react-redux';
import { categoryLoaded, loadCategory } from '../../../actions';
import { getCategories } from '../../../selectors';

export const Home = () => {
    const dispatch = useDispatch();
    const categories = useSelector(getCategories());
    React.useEffect(()=>{
        dispatch(loadCategory());
        return (()=>{
            dispatch(categoryLoaded([]));
        })
    },[])
    return (
        <Body title={'Bikram - Home'}>
            <Slider sliders={[
                `${process.env.PUBLIC_URL + '/images/banner_drawing.jpg'}`,
                `${process.env.PUBLIC_URL + '/images/banner_long.jpg'}`,
                `${process.env.PUBLIC_URL + '/images/banner sketch book.jpg'}`
            ]} />
            <Section1>
                <Row>
                    <Col md={'6'}>
                        <div style={{padding: '1rem'}}>
                            <HeaderTopText color={'#e947a5'} text={'welcome to our'} />
                            <HeaderText color={'#0e0e12'} text={'Full-service copy & printing center'} />
                            <br />
                            <p>
                            All type of excercise notebook, DC,A4,Board Binding excercise notebook, college notebook. Titas and Bikram Practical Notebook, Sheet Board, Graph Excercise book, Drawing Book, Sketch Book, Outline & Graph Sheet, Register book Special & Ordinary, cash book, Ledger book,Student Attendance Register, Conference pad, Scrapbook, Arcfile, Duplicate book,Cover file, Office Stationery etc. 
                            </p>
                        </div>
                    </Col>
                    <Col md={'6'}>
                        <div className='text-center' style={{padding: '1rem'}}>
                            <Image src={`${process.env.PUBLIC_URL + '/images/BIKRAM and titas.png'}`} />
                        </div>
                    </Col>
                </Row>
            </Section1>
            <Section2>
                <div>
                    <HeaderTopText color={'#fcd63c'} text={'just give us a call'} />
                    <HeaderText color={'#ffffff'} text={'We Offer a Wide Range of Services'} />
                    <br />
                    <p>
                        Your business is too important to rely on beginner design. Whether you're looking to promote a product or advertise a service, we can help you design a layout that will reach your audience and get your message across.  
                    </p>
                    <ul className='ul'>
                        {
                            categories.map((cat)=>(
                                <li className='li white'>{cat.name}</li>
                            ))
                        }
                    </ul>
                </div>
            </Section2>
            <Panel1 left={<div className='p-4'>
                <Row className='item-box-container'>
                    <Col xs={6}>
                        <div className='item-box'>
                            <div className='inner'>
                                <Icon name='print' className='icon' size='huge' />
                                <p className='text'>Copying Services</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className='item-box'>
                            <div className='inner'>
                                <Icon name='paint brush' className='icon' size='huge' />
                                <p className='text'>Design Services</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className='item-box'>
                            <div className='inner'>
                                <Icon name='print' className='icon' size='huge' />
                                <p className='text'>Copying Services</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className='item-box'>
                            <div className='inner'>
                                <Icon name='paint brush' className='icon' size='huge' />
                                <p className='text'>Design Services</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>} 
            right={<div className='p-5'>
                <div className='text-center p-5'>
                            <HeaderTopText color={'#e947a5'} text={'Provide Quality'} />
                            <HeaderText color={'#0e0e12'} text={'Copy Services'} />
                            <br />
                            <p>
                            All type of excercise notebook, DC,A4,Board Binding excercise notebook, college notebook. Titas and Bikram Practical Notebook, Sheet Board, Graph Excercise book, Drawing Book, Sketch Book, Outline & Graph Sheet, Register book Special & Ordinary, cash book, Ledger book,Student Attendance Register, Conference pad, Scrapbook, Arcfile, Duplicate book,Cover file, Office Stationery etc. 
                            </p>
                </div>
            </div>} />
            <Section3>
                <div className='p-3 text-center'>
                    <br />
                    <HeaderTopText color={'#e947a5'} text={'welcome to our'} />
                    <br />
                    <HeaderText color={'#fff'} text={'Full-service copy & printing center'} />                    
                    <br />
                    <br />
                </div>
            </Section3>
            <ProductFilter filters={[]}>
                <HeaderTopText color={'#e947a5'} text={'recent works'} />
                <br />
                <HeaderText color={'#0e0e12'} text={'Our Projects'} /> 
            </ProductFilter>
            <Contact />
            <Blogs only10>
                <HeaderText color={'#0e0e12'} text={'Fresh blog post'} /> 
            </Blogs>
        </Body>
    )
}