import * as React from 'react';
import { Body } from '../utils/body';
import { SectionHeader } from '../utils/section-header';
import { Panel1 } from '../utils/panel1';
import { Col, Image, Row } from 'react-bootstrap';
import { HeaderTopText } from '../utils/header-top-text';
import { HeaderText } from '../utils/header-text';
import { Section2 } from '../utils/section2';
import { Section1 } from '../utils/section1';

export const About = () => {
    return (
        <Body title={'About Us - Bikram'}>
            <SectionHeader section={'About Us'} />
            <Panel1 addBgImage left={
                <Image style={{width: '100%'}} src={ `${process.env.PUBLIC_URL + '/images/Bikram A4.png'}`} />
            } right={
                <div className='p-5'>
                    <div className='p-5 text-center'>
                        <HeaderTopText color={'#47d6e8'} text={'Our Story'} />
                        <HeaderText color={'#000'} text={'Our History'} />
                        <br />
                        <p>All type of excercise notebook, DC,A4,Board Binding excercise notebook, college notebook. Titas and Bikram Practical Notebook, Sheet Board, Graph Excercise book, Drawing Book, Sketch Book, Outline & Graph Sheet, Register book Special & Ordinary, cash book, Ledger book,Student Attendance Register, Conference pad, Scrapbook, Arcfile, Duplicate book,Cover file, Office Stationery etc.</p>
                    </div>
                </div>
            } />
            <Section2>
                <div>
                    <HeaderTopText color={'#fcd63c'} text={'just give us a call'} />
                    <HeaderText color={'#ffffff'} text={'We Offer a Wide Range of Services'} />
                    <br />
                    <p>
                        Your business is too important to rely on beginner design. Whether you're looking to promote a product or advertise a service, we can help you design a layout that will reach your audience and get your message across.  
                    </p>
                </div>
            </Section2>
            <Section1>
                <Row>
                    <Col md={'6'}>
                        <div style={{padding: '1rem'}}>
                            <HeaderTopText color={'#e947a5'} text={'Our Story'} />
                            <HeaderText color={'#0e0e12'} text={'Our History'} />
                            <br />
                            <p>
                            All type of excercise notebook, DC,A4,Board Binding excercise notebook, college notebook. Titas and Bikram Practical Notebook, Sheet Board, Graph Excercise book, Drawing Book, Sketch Book, Outline & Graph Sheet, Register book Special & Ordinary, cash book, Ledger book,Student Attendance Register, Conference pad, Scrapbook, Arcfile, Duplicate book,Cover file, Office Stationery etc. 
                            </p>
                        </div>
                    </Col>
                    <Col md={'6'}>
                        <div style={{padding: '1rem'}}>
                        <Image src={`${process.env.PUBLIC_URL + '/images/BIKRAM and titas.png'}`} />
                        </div>
                    </Col>
                </Row>
            </Section1>
        </Body>
    )
}