import * as React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

import { ProductGrid } from './product-grid';

import './styles/product-filter.css';
import { useDispatch, useSelector } from 'react-redux';
import { loadProducts, productsLoaded } from '../../actions';
import { getProducts } from '../../selectors';
import { Config } from '../../config';

export const ProductFilter = ({children}) => {
    const dispatch = useDispatch();
    const products = useSelector(getProducts);
    React.useEffect(()=>{
        dispatch(loadProducts());
        return (()=>{
            dispatch(productsLoaded([]));
        })
    },[])
    const [activeItem, setActiveItem] = React.useState('all');
    const categories = React.useMemo(()=>{
        return Array.from(new Set(products.map(p=>JSON.stringify({name: p.category.name, id: p.category._id})))).map(a=>JSON.parse(a));
    },[products])
    const productList = React.useMemo(()=>{
        if(activeItem==='all') {
            return products.map(p=>({src: `${Config.baseImageUrl}/${p.image.split('/').join(',')}`, text: p.name}));
        }
        else {
            return products.filter(p=>p.category?._id===activeItem).map(p=>({src: `${Config.baseImageUrl}/${p.image.split('/').join(',')}`, text: p.name}));
        }
    },[products, activeItem])
    const handleItemClick = (name) => () => {
        setActiveItem(name);
    }

    return (
        <div className='pt-4 pb-4 bg-gray text-center product-filter'>
            <div className='mt-4 mb-4'>
                {children}
                <ButtonGroup className='button-group'>
                    <Button className='button' active={activeItem === 'all'} onClick={handleItemClick('all')}>All</Button>
                    {
                        categories.map(cat=>(
                            <Button className='button' active={activeItem === cat.id} onClick={handleItemClick(cat.id)}>{cat.name}</Button>
                        ))
                    }
                </ButtonGroup>
            </div>
            <ProductGrid images={productList} />
        </div>
    )
}