import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './styles/panel1.css';

export const Panel1 = ({left, right, addBgImage=false}) => {
    return (
        <div className={`panel1 ${addBgImage && 'panel1-bg-image'}`}>
            <Container>
                <Row>
                    <Col className='left-panel' md={6}>
                        <div className='inner'>
                            {left}
                        </div>
                    </Col>
                    <Col className='right-panel' md={6}>
                        <div className='inner'>
                            {right}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}