import moment from 'moment';
import * as React from 'react';
import { Icon } from 'semantic-ui-react';

import './styles/footer.css';


export const Footer = () => {
    return (
        <footer className='footer'>
            <div class="">
                <ul class="wrapper">
                    <li>
                        <a href="#" class="link">
                        <Icon className='fab' name='facebook f' />
                        facebook
                        </a>
                    </li>
                    <li>
                        <a href="#" class="link">
                        <Icon className='fab' name='twitter' />
                        twitter
                        </a>
                    </li>
                    <li>
                        <a href="#" class="link">
                        <Icon className='fab' name='instagram' />
                        instagram
                        </a>
                    </li>
                    <li>
                        <a href="#" class="link">
                        <Icon className='fab' name='youtube' />
                        youtube
                        </a>
                    </li>
                </ul>
            </div>
            <div class="separador"></div>
            <p class="text">Copyright Bikram @{moment().year()}</p>
        </footer>
    )
}