import * as React from 'react';
import { Carousel, Image } from 'react-bootstrap';

import './styles/slider.css';

export const Slider = ({sliders=[]}) => {
    return (
        <Carousel fade indicators wrap touch className='slider'>
            {
                sliders.map(slider=>(
                    <Carousel.Item>
                        <Image className='slider-image' src={slider} />
                    </Carousel.Item>
                ))
            }
        </Carousel>
    )
}