import * as React from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import './styles/blogs.css';
import { HeaderTopText } from './header-top-text';
import './styles/product-filter.css';
import { blogsLoaded, loadBlogs } from '../../actions';
import { getBlogs } from '../../selectors';
import moment from 'moment';
import { Link } from 'react-router-dom';


export const Blogs = ({children, only10}) => {
    const blogs = useSelector(getBlogs);
    const dispatch = useDispatch();
    const blogsSorted = React.useMemo(()=>{
        const sort = [...blogs].sort((a,b)=>new Date(b.createdDate)-new Date(a.createdDate));
        if(only10) {
            return sort.slice(0,10);
        }
        else
            return sort;
    },[blogs, only10])
    React.useEffect(()=>{
        dispatch(loadBlogs());
        return (()=>{
            dispatch(blogsLoaded([]));
        })
    },[])
    return (
        <div className='pt-4 pb-4 bg-gray product-filter'>
            <Container className='mt-4 mb-4'>
                <div className='text-left'>
                    {children}
                </div>
                <div className='blogs'>
                    {
                        blogsSorted.length ?
                        [...blogsSorted].map(blog=>(
                            <div className='blog-item'>
                                <div className='content'>
                                    <div className='date'>
                                        <span className='day'>
                                            {moment(blog?.createdDate).day()}
                                        </span>
                                        <span className='month'>
                                            {moment(blog?.createdDate).format('MMM')}
                                        </span>
                                    </div>
                                    <div className='info'>
                                        <div className='meta'>
                                            <span className='date-info'>
                                                {moment(blog?.createdDate).format('DD.MM.YYYY')}
                                            </span>
                                        </div>
                                        <Link to={`/blogs/${blog?._id}`} className='title'>
                                            {blog?.title}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        <HeaderTopText color={'#e947a5'} text={'Blogs coming soon!!'} />
                    }
                </div>
            </Container>
        </div>
    )
}