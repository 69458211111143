import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './styles/section3.css';

export const Section3 = ({children}) => {
    return (
        <div className='section-3'>
            <Container>
                <Row>
                    <Col md={3} />
                    <Col md={6}>
                        {children}
                    </Col>
                    <Col md={3} />
                </Row>
            </Container>
        </div>
    )
}